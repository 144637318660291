import React, { useState, createRef, useEffect } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import ProjectCard from "./project"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"
import Hero from "../../components/hero"

const Company = ({
  data: {
    markdownRemark: {
      frontmatter: { projects },
    },
  },
}) => {
  const filterKeys = {
    dataSolutions: {
      key: "solutions",
      ref: createRef(),
    },
    services: {
      key: "services",
      ref: createRef(),
    },
    sectors: {
      key: "sectors",
      ref: createRef(),
    },
    regions: {
      key: "regions",
      ref: createRef(),
    },
  }

  const handleFilterOptions = () => {
    const solutions = []
    const sectors = []
    const regions = []
    const services = []

    projects.forEach(i => {
      solutions.push(...i.solutions)
      sectors.push(...i.sectors)
      regions.push(...i.regions)
      services.push(...i.services.map(i => i.title))
    })

    setSolutionOptions(
      [...new Set(solutions)]
        .map(i => {
          return {
            name: i,
            count: solutions.filter(s => s === i).length,
          }
        })
        .sort((a, b) => b.count - a.count)
    )
    setServiceOptions(
      [...new Set(services)]
        .map(i => {
          return {
            name: i,
            count: services.filter(s => s === i).length,
          }
        })
        .sort((a, b) => b.count - a.count)
    )
    setSectorOptons(
      [...new Set(sectors)]
        .map(i => {
          return {
            name: i,
            count: sectors.filter(s => s === i).length,
          }
        })
        .sort((a, b) => b.count - a.count)
    )
    setRegionOptions(
      [...new Set(regions)]
        .map(i => {
          return {
            name: i,
            count: regions.filter(s => s === i).length,
          }
        })
        .sort((a, b) => b.count - a.count)
    )
  }

  const filterFormRef = createRef()

  const projectCount = 30

  const [filteredProjects, setFilteredProjects] = useState([
    ...projects.slice(0, projectCount),
  ])

  const [solutionOptions, setSolutionOptions] = useState([])
  const [sectorOptions, setSectorOptons] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [serviceOptions, setServiceOptions] = useState([])

  const [resetActive, isResetActive] = useState(false)

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(projects.length > projectCount)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true)
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = filteredProjects.length
      const isMore = currentLength < projects.length
      const nextResults = isMore
        ? projects.slice(currentLength, currentLength + projectCount)
        : []
      setFilteredProjects([...filteredProjects, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = filteredProjects.length < projects.length
    setHasMore(isMore)
  }, [filteredProjects]) //eslint-disable-line

  // Runs only page loading
  useEffect(() => {
    handleFilterOptions()
  }, []) //eslint-disable-line

  // TODO: Order filter options from most frequent to least frequent

  const handleFilterProjects = (option, value) => {
    filterFormRef.current.reset()
    option.ref.current.value = value

    if (!value) {
      setFilteredProjects([...projects.slice(0, projectCount)])
      return
    }
    isResetActive(true)
    if (option.key === "services") {
      const filtered = projects.filter(i => {
        return i[option.key].map(i => i.title).includes(value)
      })
      setFilteredProjects(filtered)
    } else {
      const filtered = projects.filter(i => i[option.key].includes(value))
      setFilteredProjects(filtered)
    }
  }

  return (
    <Layout>
      <Seo title="Projects" />
      {/* <div
        style={{
          backgroundImage: `url('https://storage.googleapis.com/keshif-website/Projects/Map.svg')`,
          backgroundSize: "auto 117%",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
        className="py-10 sm:py-16 lg:py-14 lg:overflow-hidden bg-blue-100"
      >
        <div className="container">
          <div className="lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="max-w-md sm:max-w-2xl text-left lg:flex">
              <div className="lg:py-12 max-w-md">
                <h1 className="mt-4 sm:mt-5 lg:mt-6">
                  <span className="text-gray-900 font-light">
                    Where data meets
                  </span>
                  <span className="font-medium block">its purpose</span>
                </h1>
                <hr className="w-12 bg-transparent border-t-2 border-purple-600 mt-4 mb-6" />
                <p
                  style={{
                    textShadow: "0 0 6px white, 0 0 3px white, 0 0 1px white",
                  }}
                >
                  We support the international development community, private
                  sector, governments, and nonprofits to harness the power of
                  data in solving the world’s most pressing challenges. Our team
                  has built data solutions that support program operations and
                  research in various sectors including monitoring, evaluation
                  and learning, stabilization and transition, democracy and
                  governance, and economic development.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Hero
        backgroundUrl="https://storage.googleapis.com/keshif-website/Projects/Map.svg"
        title={`Where data meets<br/><span class="font-medium">its purpose</span>`}
        description="We support the international development community, private
                  sector, governments, and nonprofits to harness the power of
                  data in solving the world’s most pressing challenges. Our team
                  has built data solutions that support program operations and
                  research in various sectors including monitoring, evaluation
                  and learning, stabilization and transition, democracy and
                  governance, and economic development.
        "
      />

      <section>
        <div className="container py-5 lg:pt-10 lg:pb-24 grid gap-10">
          <form
            ref={filterFormRef}
            onReset={() => {
              setFilteredProjects([...projects.slice(0, projectCount)])
              isResetActive(false)
            }}
            className="mt-5 flex justify-between items-center rounded bg-white overflow-hidden border-gray-200 border"
          >
            <div className="px-4 py-5 sm:p-6 border-gray-100 border-r h-full flex items-center">
              <div className="flex gap-1 max-w-max flex-col items-center">
                <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
                <span className="font-medium">Filter</span>
              </div>
            </div>
            <div className="px-4 py-4 grid grid-cols-1 md:grid-cols-4 flex-1 gap-4">
              <div>
                <label
                  htmlFor="services"
                  className="block text-sm font-medium text-gray-700"
                >
                  Services
                </label>
                <select
                  ref={filterKeys.services.ref}
                  id="services"
                  onChange={e =>
                    handleFilterProjects(filterKeys.services, e.target.value)
                  }
                  name="location"
                  className="select"
                >
                  <option value="">Select</option>
                  {serviceOptions.map(i => (
                    <option value={i.name} key={i.name}>
                      {i.name} ({i.count})
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="solutions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Solutions
                </label>
                <select
                  ref={filterKeys.dataSolutions.ref}
                  id="solutions"
                  onChange={e =>
                    handleFilterProjects(
                      filterKeys.dataSolutions,
                      e.target.value
                    )
                  }
                  name="location"
                  className="select"
                >
                  <option value="">Select</option>
                  {solutionOptions.map(i => (
                    <option value={i.name} key={i.name}>
                      {i.name} ({i.count})
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="sectors"
                  className="block text-sm font-medium text-gray-700"
                >
                  Sectors
                </label>
                <select
                  ref={filterKeys.sectors.ref}
                  id="sectors"
                  name="sectors"
                  onChange={e =>
                    handleFilterProjects(filterKeys.sectors, e.target.value)
                  }
                  className="select"
                >
                  <option value="">Select</option>
                  {sectorOptions.map(i => (
                    <option value={i.name} key={i.name}>
                      {i.name} ({i.count})
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="regions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Regions
                </label>
                <select
                  id="regions"
                  name="regions"
                  ref={filterKeys.regions.ref}
                  onChange={e =>
                    handleFilterProjects(filterKeys.regions, e.target.value)
                  }
                  className="select"
                >
                  <option value="">Select</option>
                  {regionOptions.map(i => (
                    <option value={i.name} key={i.name}>
                      {i.name} ({i.count})
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <button
                disabled={!resetActive}
                type="reset"
                className={`text-purple-600 ${
                  resetActive
                    ? "hover:text-purple-900"
                    : "opacity-50 cursor-not-allowed select-none"
                } font-medium`}
              >
                Clear
              </button>
            </div>
          </form>
          <TransitionGroup className="grid lg:grid-cols-3 md:grid-cols-3 gap-6 gap-y-12 items-stretch">
            {filteredProjects.map(project => (
              <CSSTransition
                key={project.title}
                timeout={500}
                classNames="item"
              >
                <ProjectCard project={project} />
              </CSSTransition>
            ))}
          </TransitionGroup>
          {hasMore && !resetActive && (
            <button
              onClick={handleLoadMore}
              className="font-medium btn-secondary max-w-max mx-auto"
            >
              Load More Projects
            </button>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        projects {
          opendata
          client {
            name
          }
          title
          slug
          sectors
          solutions
          services {
            title
          }
          regions
        }
      }
    }
  }
`

export default Company
